import {
  GET_LOCK_MARK,
  GET_LOCK_MARK_SUCCESS,
  GET_LOCK_MARK_ERROR,
  ADD_UPDATE_LOCK_MARK,
  ADD_UPDATE_LOCK_MARK_SUCCESS,
  ADD_UPDATE_LOCK_MARK_ERROR,
  SEARCH_LOCK_MARKS,
  SEARCH_LOCK_MARKS_SUCCESS,
  AUTOCOMPLETE_LOCK_MARKS,
  AUTOCOMPLETE_LOCK_MARKS_SUCCESS,
  AUTOCOMPLETE_LOCK_MARKS_ERROR,
  GET_LOCKED_BRANDS_BY_USER,
  GET_LOCKED_BRANDS_BY_USER_SUCCESS,
  GET_LOCKED_BRANDS_BY_USER_ERROR,
  RESET_SUCCESS_BRAND_LOCK_UPDATE,
} from '../constants/ActionsTypes'

const initialState = {
  lockMark: undefined,
  lockedBrands: [],
  success: false,
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOCK_MARK:
      return { ...state, lockMark: null, success: false }
    case GET_LOCK_MARK_SUCCESS:
      return { ...state, lockMark: action.data }
    case GET_LOCK_MARK_ERROR:
      return { ...state, lockMark: action }
    case ADD_UPDATE_LOCK_MARK:
      return { ...state, success: false }
    case ADD_UPDATE_LOCK_MARK_SUCCESS:
      const updatedBrand = action.payload
      const updatedLockedBrands = state.lockedBrands.filter((brand) => brand.id !== updatedBrand.id)
      updatedLockedBrands.push(updatedBrand)
      return { ...state, success: true, lockMark: undefined, lockedBrands: updatedLockedBrands }
    case ADD_UPDATE_LOCK_MARK_ERROR:
      return { ...state, success: false }
    case SEARCH_LOCK_MARKS:
      return { ...state, searchRequest: action.payload, search: null, success: false }
    case SEARCH_LOCK_MARKS_SUCCESS:
      return { ...state, search: action.payload.response }
    case AUTOCOMPLETE_LOCK_MARKS:
      return { ...state, autocompleteData: null }
    case AUTOCOMPLETE_LOCK_MARKS_SUCCESS:
      return { ...state, autocompleteData: action.payload }
    case AUTOCOMPLETE_LOCK_MARKS_ERROR:
      return { ...state, message: action.payload }
    case GET_LOCKED_BRANDS_BY_USER:
      return { ...state, lockedBrands: [] }
    case GET_LOCKED_BRANDS_BY_USER_SUCCESS:
      return { ...state, lockedBrands: action.data }
    case GET_LOCKED_BRANDS_BY_USER_ERROR:
      return { ...state, lockedBrands: [] }
    case RESET_SUCCESS_BRAND_LOCK_UPDATE:
      return { ...state, success: false }
    default:
      return state
  }
}

export default rootReducer
