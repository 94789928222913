import {
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_ERROR,
  CLEAR_ERROR,
  SHOW_MESSAGE,
  CLEAR_MESSAGE,
  SET_ACTION_BAR_SHOW_REMOVE,
  TOGGLE_FIX_HEADER,
  SET_HEADER_HEIGHT,
  INIT_ACTION_BAR,
  GET_CURRENCY_TYPES,
  GET_CURRENCY_TYPES_SUCCESS,
  GET_BANKS,
  GET_BANKS_SUCCESS,
  GET_BANK_REFERENCES,
  GET_BANK_REFERENCES_SUCCESS,
  TRIGGER_UPDATE_NOTIFICATION,
  HIDE_UPDATE_NOTIFICATION,
  CHECK_FOR_UPDATES,
} from '../constants/ActionsTypes'

const initialState = {
  AdminActionBar: {},
  currencyTypes: null,
  banks: null,
  bankReferences: null,
  fixHeader: null,
  headerHeight: 0,
  showUpdateNotification: false,
  latestVersion: '',
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, showLoader: true }
    case HIDE_LOADER:
      return { ...state, showLoader: false }
    case SHOW_ERROR:
      return { ...state, error: action.payload }
    case CLEAR_ERROR:
      return { ...state, error: undefined }
    case SHOW_MESSAGE:
      return { ...state, message: action.payload }
    case CLEAR_MESSAGE:
      return { ...state, message: undefined }
    case SET_ACTION_BAR_SHOW_REMOVE:
      return { ...state, AdminActionBar: { showRemove: action.payload } }
    case INIT_ACTION_BAR:
      return { ...state, AdminActionBar: {} }
    case GET_CURRENCY_TYPES:
      return { ...state, currencyTypes: null }
    case GET_CURRENCY_TYPES_SUCCESS:
      return { ...state, currencyTypes: action.payload.response.data }
    case GET_BANKS:
      return { ...state, banks: null }
    case GET_BANKS_SUCCESS:
      return { ...state, banks: action.payload.response.data }
    case GET_BANK_REFERENCES:
      return { ...state, bankReferences: null }
    case GET_BANK_REFERENCES_SUCCESS:
      return { ...state, bankReferences: action.payload.response.data }
    case TOGGLE_FIX_HEADER:
      return { ...state, fixHeader: !state.fixHeader }
    case SET_HEADER_HEIGHT:
      return { ...state, headerHeight: action.payload }
    case TRIGGER_UPDATE_NOTIFICATION:
      return { ...state, showUpdateNotification: true, latestVersion: action.payload }
    case HIDE_UPDATE_NOTIFICATION:
      return { ...state, showUpdateNotification: false }
    case CHECK_FOR_UPDATES:
      return state
    default:
      return state
  }
}

export default rootReducer
