import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  GET_CURRENCY_TYPES,
  GET_BANKS,
  GET_BANK_REFERENCES,
  CHECK_FOR_UPDATES,
} from '../constants/ActionsTypes'

import { getCurrencyTypes, getBanks, getBankReferencesApi } from '../api/provisionalReceipts'

import {
  getCurrencyTypesSuccess,
  getBanksSuccess,
  getBankReferencesSuccess,
  triggerUpdateNotification,
} from '../actions'

function* getCurrencyTypesRequest() {
  try {
    const { data } = yield call(getCurrencyTypes)
    yield put(getCurrencyTypesSuccess(data))
  } catch (error) {}
}

function* getBanksRequest() {
  try {
    const { data } = yield call(getBanks)
    yield put(getBanksSuccess(data))
  } catch (error) {}
}

function* getBankReferencesRequest() {
  try {
    const { data } = yield call(getBankReferencesApi)
    yield put(getBankReferencesSuccess(data))
  } catch (error) {}
}

function* checkForUpdatesSaga() {
  const url = `/static/version.js?t=${Date.now()}`

  try {
    const response = yield call(fetch, url)
    const latestVersion = yield response.text()
    const storedVersion = localStorage.getItem('appVersion')

    if (!storedVersion) {
      localStorage.setItem('appVersion', latestVersion)
    } else if (storedVersion !== latestVersion) {
      yield put(triggerUpdateNotification(latestVersion))
    }
  } catch (error) {}
}

export function* watchForCheckForUpdates() {
  yield takeLatest(CHECK_FOR_UPDATES, checkForUpdatesSaga)
}

export function* getCurrencyTypesSaga() {
  yield takeLatest(GET_CURRENCY_TYPES, getCurrencyTypesRequest)
}

export function* getBanksSaga() {
  yield takeLatest(GET_BANKS, getBanksRequest)
}

export function* getBankReferencesSaga() {
  yield takeLatest(GET_BANK_REFERENCES, getBankReferencesRequest)
}

export default function* rootSaga() {
  yield all([
    fork(getCurrencyTypesSaga),
    fork(getBanksSaga),
    fork(getBankReferencesSaga),
    fork(watchForCheckForUpdates),
  ])
}
