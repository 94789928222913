import {
  GET_CURRENT_ACCOUNT,
  GET_CURRENT_ACCOUNT_SUCCESS,
  CREDIT_LIMIT_INCREASE_REQUEST,
  CREDIT_LIMIT_INCREASE_SUCCESS,
  GET_CREDIT_LIMIT_REQUESTS,
  GET_CREDIT_LIMIT_REQUESTS_SUCCESS,
  UPLOAD_PROVISIONAL_RECEIPT_SUCCESS,
} from '../constants/ActionsTypes'

const initialState = {
  results: [],
  creditLimitRequests: [],
  isLoading: false,
  success: false,
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ACCOUNT:
      return { ...state }
    case GET_CURRENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        results: action.data,
      }
    case CREDIT_LIMIT_INCREASE_REQUEST:
      return { ...state, success: false }
    case CREDIT_LIMIT_INCREASE_SUCCESS:
      return { ...state, success: true }
    case GET_CREDIT_LIMIT_REQUESTS:
      return { ...state, creditLimitRequests: [], isLoading: true, success: false }
    case GET_CREDIT_LIMIT_REQUESTS_SUCCESS:
      return { ...state, creditLimitRequests: action.data, isLoading: false, success: true }
    case UPLOAD_PROVISIONAL_RECEIPT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default rootReducer
