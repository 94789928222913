import Axios from 'axios'
import moment from 'moment-business-days'
const FileSaver = require('file-saver')

export const getLockMark = async (LockMarkId, filter) => {
  const response = await Axios.get(`/lockMarks/${LockMarkId}`, {
    params: {
      filter,
    },
  })
  return response
}

export const searchLockMarks = async (searchParams) => {
  const response = await Axios.post('/lockMarks/search', searchParams)
  return response.data
}

export const saveLockMark = async (data) => {
  const response = await Axios.put('/lockMarks', data)
  return response.data
}

export const getLockedBrandsByUser = async (userId) => {
  const response = await Axios.get(`/lockMarks/getCurrentLocksForUser?userId=${userId}`)
  return response
}

const MINUTES_TO_WAIT_IN_MILLISECONDS = 2 * 60 * 1000

export const exportLockMarks = async (filter) => {
  const config = {
    params: {
      filter,
    },
    responseType: 'arraybuffer',
    timeout: MINUTES_TO_WAIT_IN_MILLISECONDS,
  }

  const response = await Axios.get(`/lockMarks/exportLockMarks`, config)
  if (response && response.data) {
    FileSaver.saveAs(new Blob([response.data]), `LockMarks-${moment().format('YYYYMMDD')}.xlsx`, {
      autoBOM: true,
    })
  }
}
