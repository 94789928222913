import { all } from 'redux-saga/effects'
import authSagas from './Auth'
import usersSagas from './Users'
import clientsSagas from './Clients'
import provisionalReceiptsSagas from './provisionalReceipts'
import commonSagas from './Common'
import sellersSagas from './Sellers'
import branchesSagas from './Branches'
import transportsSagas from './Transports'
import mainGroupsSagas from './MainGroups'
import subGroupsSagas from './SubGroups'
import groupsSagas from './Groups'
import segmentsSagas from './Segments'
import brandsSagas from './Brands'
import catalogSagas from './Catalog'
import catalogEntriesSagas from './CatalogEntries'
import productsSagas from './Products'
import dealsSagas from './Deals'
import ordersSagas from './Orders'
import currentAccount from './CurrentAccount'
import statistics from './Statistics'
import configureClient from './ConfigureClient'
import offline from './Offline'
import quotationSaga from './Quotation'
import bannerSaga from './Banners'
import categoriesSaga from './Categories'
import lockMarkSaga from './LockMark'
import connectivity from './Connectivity'
import appointmentSaga from './Appointment'
import dashboardSaga from './Dashboard'
import applicationsSaga from './Applications'
import characteristics from './Characteristics'
import accountFormsSaga from './AccountForm'
import userConfig from './UserConfig'
import persons from './Persons'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    usersSagas(),
    branchesSagas(),
    transportsSagas(),
    clientsSagas(),
    provisionalReceiptsSagas(),
    commonSagas(),
    sellersSagas(),
    mainGroupsSagas(),
    groupsSagas(),
    subGroupsSagas(),
    segmentsSagas(),
    brandsSagas(),
    catalogSagas(),
    catalogEntriesSagas(),
    productsSagas(),
    dealsSagas(),
    ordersSagas(),
    currentAccount(),
    statistics(),
    configureClient(),
    offline(),
    quotationSaga(),
    bannerSaga(),
    categoriesSaga(),
    lockMarkSaga(),
    connectivity(),
    appointmentSaga(),
    dashboardSaga(),
    applicationsSaga(),
    characteristics(),
    dashboardSaga(),
    accountFormsSaga(),
    userConfig(),
    persons(),
  ])
}
