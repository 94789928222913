import {
  STORE_RECEIPTS_DATA,
  UPLOAD_PROVISIONAL_RECEIPT,
  UPLOAD_PROVISIONAL_RECEIPT_SUCCESS,
  GET_PROVISIONAL_RECEIPTS,
  GET_PROVISIONAL_RECEIPTS_SUCCESS,
  GET_TAX_WITHHOLDINGS_TYPES,
  GET_TAX_WITHHOLDINGS_TYPES_SUCCESS,
  CLEAR_RECEIPTS_DATA,
  SIGNOUT_USER_SUCCESS,
  DELETE_PROVISIONAL_RECEIPT_SUCCESS,
  RESET_DELETE_RECEIPT_SUCCESS,
} from '../constants/ActionsTypes'

const storedData =
  typeof window !== 'undefined' && window.localStorage.getItem('provisionalReceipts')
    ? JSON.parse(window.localStorage.getItem('provisionalReceipts'))
    : {}

const initialState = {
  selectedReceipts: storedData.selectedReceipts || [],
  total: storedData.total || 0,
  isReceiptUploadedSuccessfully: storedData.isReceiptUploadedSuccessfully || false,
  taxWithholdingsTypes: storedData.taxWithholdingsTypes || null,
  deleteReceiptSuccess: false,
}

const addReceipt = (state, payload) => {
  const totalWithDiscount = parseFloat(payload.totalWithDiscount) || 0

  const adjustedTotalWithDiscount =
    payload.receiptType !== 'DG' ? totalWithDiscount : -Math.abs(totalWithDiscount)

  const total = (parseFloat(state.total) + adjustedTotalWithDiscount).toFixed(2)

  return {
    ...state,
    selectedReceipts: [...state.selectedReceipts, payload],
    total: parseFloat(total),
  }
}

const updateReceipt = (state, payload) => {
  const receiptToUpdate = state.selectedReceipts.find((receipt) => receipt.key === payload.key)

  const newTotalWithDiscount =
    payload.receiptType !== 'DG' ? payload.totalWithDiscount : -Math.abs(payload.totalWithDiscount)
  const oldTotalWithDiscount = receiptToUpdate
    ? receiptToUpdate.receiptType !== 'DG'
      ? receiptToUpdate.totalWithDiscount
      : -Math.abs(receiptToUpdate.totalWithDiscount)
    : 0

  const diff = newTotalWithDiscount - oldTotalWithDiscount

  const updatedReceipts = state.selectedReceipts.map((receipt) => {
    if (receipt.key === payload.key) {
      return { ...receipt, ...payload }
    }
    return receipt
  })

  const total = parseFloat((state.total + diff).toFixed(2))
  return {
    ...state,
    selectedReceipts: updatedReceipts,
    total,
  }
}

const deleteReceipt = (state, payload) => {
  const receiptToDelete = state.selectedReceipts.find((receipt) => receipt.key === payload.key)
  const totalWithDiscount =
    payload.receiptType !== 'DG'
      ? receiptToDelete.totalWithDiscount
      : -Math.abs(receiptToDelete.totalWithDiscount)
  const total = parseFloat((state.total - totalWithDiscount).toFixed(2))
  const updatedReceipts = state.selectedReceipts.filter((receipt) => receipt.key !== payload.key)

  return {
    ...state,
    selectedReceipts: updatedReceipts,
    total,
    success: false,
    sellerReceipts: null,
    isLoadingGetProvisionalReceipts: false,
  }
}

const clearReceiptsData = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('provisionalReceipts')
    window.localStorage.removeItem('provisionalReceiptsClientCode')
    window.localStorage.removeItem('provisionalReceiptsClientName')

    // this is for the old provisional receipts - delete this after a while
    window.localStorage.removeItem('provisoryReceipts')
    window.localStorage.removeItem('provisoryReceiptsClientCode')
    window.localStorage.removeItem('provisoryReceiptsClientName')
  }
  return {
    ...initialState,
    isReceiptUploadedSuccessfully: true,
  }
}

function rootReducer(state = initialState, action) {
  let newState

  switch (action.type) {
    case STORE_RECEIPTS_DATA: {
      const { payload, operation } = action

      switch (operation) {
        case 'add':
          newState = addReceipt(state, payload)
          break
        case 'update':
          newState = updateReceipt(state, payload)
          break
        case 'delete':
          newState = deleteReceipt(state, payload)
          break
        default:
          newState = state
      }
      break
    }
    case UPLOAD_PROVISIONAL_RECEIPT:
      newState = { ...state, isReceiptUploadedSuccessfully: false }
      break
    case UPLOAD_PROVISIONAL_RECEIPT_SUCCESS:
      newState = clearReceiptsData()
      break
    case GET_PROVISIONAL_RECEIPTS:
      return { ...state, isLoadingGetProvisionalReceipts: true }
    case GET_PROVISIONAL_RECEIPTS_SUCCESS:
      return {
        ...state,
        sellerReceipts: action.payload.data.receipts,
        isLoadingGetProvisionalReceipts: false,
      }
    case GET_TAX_WITHHOLDINGS_TYPES:
      newState = { ...state, taxWithholdingsTypes: null }
      break
    case GET_TAX_WITHHOLDINGS_TYPES_SUCCESS:
      newState = { ...state, taxWithholdingsTypes: action.payload.response.data }
      break
    case CLEAR_RECEIPTS_DATA:
      newState = { ...state, selectedReceipts: [], total: 0 }
      break
    case SIGNOUT_USER_SUCCESS:
      newState = { ...state, selectedReceipts: [], total: 0 }
      break
    case DELETE_PROVISIONAL_RECEIPT_SUCCESS:
      newState = { ...state, deleteReceiptSuccess: true }
      break;
    case RESET_DELETE_RECEIPT_SUCCESS:
      newState = { ...state, deleteReceiptSuccess: false };
      break;
    default:
      newState = state
  }

  if (typeof window !== 'undefined') {
    const user = window.localStorage.getItem('user')

    if (user) {
      const provisionalReceiptsData = {
        selectedReceipts: newState.selectedReceipts,
        total: newState.total,
        isReceiptUploadedSuccessfully: newState.isReceiptUploadedSuccessfully,
        taxWithholdingsTypes: newState.taxWithholdingsTypes,
      }

      window.localStorage.setItem('provisionalReceipts', JSON.stringify(provisionalReceiptsData))
    } else {
      newState = initialState
    }
  }

  return newState
}

export default rootReducer
