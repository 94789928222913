import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  UPLOAD_PROVISIONAL_RECEIPT,
  GET_TAX_WITHHOLDINGS_TYPES,
  GET_PROVISIONAL_RECEIPTS,
  SUBMIT_RECEIPTS,
  DELETE_PROVISIONAL_RECEIPT,
} from '../constants/ActionsTypes';

import {
  uploadReceipt,
  submitReceipts,
  getTaxWithholdingsTypes,
  getProvisionalReceipts,
  deleteReceipt
} from '../api/provisionalReceipts';

import {
  uploadReceiptSuccess,
  submitReceiptsSuccess,
  getTaxWithholdingsTypesSuccess,
  getProvisionalReceiptsSuccess,
  deleteReceiptSuccess
} from '../actions';

function* uploadReceiptRequest({payload}) {
  try {
    const {data} = yield call(uploadReceipt, payload);
    yield put(uploadReceiptSuccess(data));
  } catch (error) {
  }
}

function* submitReceiptsRequest({payload}) {
  try {
    const {data} = yield call(submitReceipts, payload);
    yield put(submitReceiptsSuccess(data));
  } catch (error) {
    // handled in interceptor
  }
}

function* getProvisionalReceiptsRequest() {
  
  try {
    const {data} = yield call(getProvisionalReceipts);
    yield put(getProvisionalReceiptsSuccess(data));
  } catch (error) {
    // handled in interceptor
  }
}

function* getTaxWithholdingsTypesRequest() {
  try {
    const {data} = yield call(getTaxWithholdingsTypes);
    yield put(getTaxWithholdingsTypesSuccess(data));
  } catch (error) {
  }
}

function* deleteReceiptRequest({ payload }) {
  try {
    const { data } = yield call(deleteReceipt, payload);
    yield put(deleteReceiptSuccess(data));
  } catch (error) {
    // handled in interceptor
  }
}

export function* uploadReceiptSaga() {
  yield takeLatest(UPLOAD_PROVISIONAL_RECEIPT , uploadReceiptRequest);
}

export function* submitReceiptsSaga() {
  yield takeLatest(SUBMIT_RECEIPTS , submitReceiptsRequest);
}

export function* getTaxWithholdingsTypesSaga() {
  yield takeLatest(GET_TAX_WITHHOLDINGS_TYPES, getTaxWithholdingsTypesRequest);
}

export function* getProvisionalReceiptsSaga() {
  yield takeEvery(GET_PROVISIONAL_RECEIPTS, getProvisionalReceiptsRequest);
}

export function* deleteReceiptSaga() {
  yield takeLatest(DELETE_PROVISIONAL_RECEIPT, deleteReceiptRequest);
}

export default function* rootSaga() {
  yield all([
    fork(uploadReceiptSaga),
    fork(submitReceiptsSaga),
    fork(getTaxWithholdingsTypesSaga),
    fork(getProvisionalReceiptsSaga),
    fork(deleteReceiptSaga),
  ]);
}
