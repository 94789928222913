import CurrencyFormat from 'react-currency-format';
import { withTranslation } from "react-i18next";
import { Badge, Tooltip } from "antd";

const mapper = {
  'ARS': {
    prefix: '$',
    decimalSeparator: ',',
    thousandSeparator: '.',
  },
  'USD': {
    prefix: 'U$D',
    decimalSeparator: '.',
    thousandSeparator: ',',
  },
}

function DisplayAmount (props) {
const { t, currency, showNews, position } = props;
let { amount } = props;

if (!amount){
  amount = 0;
}

let config = mapper.ARS
if (currency) {
  config = mapper[currency]
}
return (
  <div style={{ display: 'flex', justifyContent: position || 'flex-end' }}>

    <CurrencyFormat
      value={amount}
      displayType={"text"}
      decimalScale={2}
      fixedDecimalScale={true}
      decimalSeparator={config.decimalSeparator}
      thousandSeparator={config.thousandSeparator}
      prefix={config.prefix}
      aria-hidden="true"
    />
    {showNews ? (
      <Tooltip
        title={t("UPDATED_PRICE")}
        placement="right"
      >
        <Badge 
          count={"!"} 
          offset={[5, 0]}
          style= {{
            backgroundColor: '#F7F312',
            color: '#000000',
            fontWeight: 'bold'
          }}
        ></Badge>
      </Tooltip>
    ) : null}
  </div>
);

}
export default withTranslation()(DisplayAmount)