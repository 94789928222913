import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects'

import {
  GET_LOCK_MARK,
  GET_LOCK_MARK_SUCCESS,
  GET_LOCK_MARK_ERROR,
  ADD_UPDATE_LOCK_MARK,
  ADD_UPDATE_LOCK_MARK_SUCCESS,
  ADD_UPDATE_LOCK_MARK_ERROR,
  EXPORT_LOCK_MARKS,
  AUTOCOMPLETE_LOCK_MARKS,
  AUTOCOMPLETE_LOCK_MARKS_SUCCESS,
  SEARCH_LOCK_MARKS,
  SEARCH_LOCK_MARKS_SUCCESS,
  GET_LOCKED_BRANDS_BY_USER,
  GET_LOCKED_BRANDS_BY_USER_SUCCESS,
  GET_LOCKED_BRANDS_BY_USER_ERROR,
} from '../constants/ActionsTypes'

import {
  getLockMark,
  exportLockMarks,
  saveLockMark,
  searchLockMarks,
  getLockedBrandsByUser,
} from '../api/LockMark'

export const getCommonState = (state) => state.common

function* exportLockMarksRequest({ payload }) {
  try {
    const responseDownload = yield call(exportLockMarks, payload)
  } catch (error) {}
}

function* callGetLockMark({ payload }) {
  const { LockMarkId, filter } = payload
  try {
    const response = yield call(getLockMark, LockMarkId, filter)
    yield put({ type: GET_LOCK_MARK_SUCCESS, data: response.data })
  } catch (error) {
    yield put({ type: GET_LOCK_MARK_ERROR, error })
  }
}

function* autocompleteLockMarksRequest({ payload }) {
  const params = yield select(getCommonState)
  const { showRemove } = params.AdminActionBar

  const query = {
    filtro: payload,
    page: 1,
    pageSize: 10,
  }

  if (showRemove) {
    query.borrado = showRemove
  }

  try {
    const lockMarks = yield call(searchLockMarks, query)
    yield put({ type: AUTOCOMPLETE_LOCK_MARKS_SUCCESS, payload: lockMarks })
  } catch (error) {}
}

function* searchLockMarksRequest({ payload }) {
  const params = yield select(getCommonState)
  const { showRemove } = params.AdminActionBar

  if (showRemove) {
    payload.borrado = showRemove
  }

  try {
    const lockMarks = yield call(searchLockMarks, payload)
    yield put({ type: SEARCH_LOCK_MARKS_SUCCESS, payload: lockMarks })
  } catch (error) {}
}

function* saveLockMarkRequest({ payload }) {
  try {
    const response = yield call(saveLockMark, payload)
    yield put({ type: ADD_UPDATE_LOCK_MARK_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: ADD_UPDATE_LOCK_MARK_ERROR, error })
  }
}

function* getLockedBrandsByUserRequest({ payload }) {
  try {
    const response = yield call(getLockedBrandsByUser, payload)
    yield put({ type: GET_LOCKED_BRANDS_BY_USER_SUCCESS, data: response.data })
  } catch (error) {
    yield put({ type: GET_LOCKED_BRANDS_BY_USER_ERROR, error })
  }
}

export function* saveLockMarkSaga() {
  yield takeLatest(ADD_UPDATE_LOCK_MARK, saveLockMarkRequest)
}

export function* getLockMarkSaga() {
  yield takeLatest(GET_LOCK_MARK, callGetLockMark)
}

export function* searchLockMarksSaga() {
  yield takeEvery(SEARCH_LOCK_MARKS, searchLockMarksRequest)
}

export function* autocompleteLockMarksSaga() {
  yield throttle(1000, AUTOCOMPLETE_LOCK_MARKS, autocompleteLockMarksRequest)
}

export function* getExportLockMarksSaga() {
  yield takeLatest(EXPORT_LOCK_MARKS, exportLockMarksRequest)
}

export function* getLockedBrandsByUserSaga() {
  yield takeLatest(GET_LOCKED_BRANDS_BY_USER, getLockedBrandsByUserRequest)
}

export default function* rootSaga() {
  yield all([
    fork(getExportLockMarksSaga),
    fork(getLockMarkSaga),
    fork(saveLockMarkSaga),
    fork(searchLockMarksSaga),
    fork(autocompleteLockMarksSaga),
    fork(getLockedBrandsByUserSaga),
  ])
}
